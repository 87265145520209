import React from 'react';
import { default as LogoSvg } from 'public/logo.svg';
import { LogoContainer, LogoLink } from './Logo.styled';
import Image from 'next/image';
import styles from './styles.module.scss';

interface LogoProps {
  logoSrc?: string;
}

export const Logo = (props: LogoProps) => {
  const { logoSrc } = props;
  return (
    <LogoContainer>
      <LogoLink href="/">
        {logoSrc ? (
          <div className={styles.imageBox}>
            <Image
              src={logoSrc}
              objectFit="contain"
              loading="lazy"
              alt="logo"
              layout="fill"
            />
          </div>
        ) : (
          // <img src={logoSrc} loading="lazy" alt="logo" />
          <LogoSvg />
        )}
      </LogoLink>
    </LogoContainer>
  );
};
