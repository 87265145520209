import styled from '@emotion/styled';

export const StyledButtonLink = styled.a(({ theme }) => ({
  padding: '14px 28px',
  textAlign: 'center',
  borderRadius: 6,
  background: theme.colors.main100,
  color: theme.colors.white,
  textDecoration: 'none',
  fontFamily: theme.fonts.roboto,
}));
