import useMediaQuery from './useMediaQuery';

const MOBILE_BREAKPOINT = 768;
const DESKTOP_BREAKPOINT = 1024;

export const useLargeDevice = (currentDeviceType: string = undefined) => {
  const isDesktopDevice = currentDeviceType !== 'mobile' && currentDeviceType !== 'tablet';
  const isLargeViewport = useMediaQuery(`(min-width: ${DESKTOP_BREAKPOINT}px)`);
  return isLargeViewport !== undefined ? isLargeViewport : isDesktopDevice;
};

export const useTabletDevice = (currentDeviceType: string = undefined) => {
  const isTabletDevice = currentDeviceType === 'tablet';
  const isMediumViewport = useMediaQuery(`(min-width: ${MOBILE_BREAKPOINT + 1}px) and (max-width: ${DESKTOP_BREAKPOINT - 1}px)`);
  return isMediumViewport !== undefined ? isMediumViewport : isTabletDevice;
};
