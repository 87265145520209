import styled from '@emotion/styled';

export const Wrapper = styled.div<{
  size?: 'small' | 'medium' | 'default';
  color?: 'default' | 'light';
}>(({ theme, size, color }) => ({
  fontSize: 18,
  marginBottom: theme.spacings.l,

  ...(size === 'medium' && {
    fontSize: 18,
  }),

  ...(size === 'small' && {
    fontSize: 18,

    [theme.mediaQueries.large]: {
      fontSize: 16,
    },
  }),

  ...(color === 'light' && {
    fontSize: 20,
    color: theme.colors.support100,
  }),

  ':last-child,:last-of-type': {
    marginBottom: 0,
  },
}));
