import { motion } from 'framer-motion';
import React, { FC } from 'react';

type LoaderProps = {
  showLoader?: boolean;
};

export const Loader: FC<LoaderProps> = ({ children }) => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.2 }}
    >
      {children}
    </motion.div>
  );
};
