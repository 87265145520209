import styled from '@emotion/styled';

export const Wrapper = styled.div<{ size?: string }>(({ theme, size }) => ({
  width: '100%',
  paddingLeft: theme.spacings.l,
  paddingRight: theme.spacings.l,

  [theme.mediaQueries.large]: {
    paddingLeft: 0,
    paddingRight: 0,
  },

  ...(size === 'm' && {
    [theme.mediaQueries.large]: {
      width: 780,
    },
  }),

  ...(size === 'l' && {
    maxWidth: 1280,

    [theme.mediaQueries.large]: {
      width: '100%',
    },
  }),
}));
