import React, {
  FC,
  useCallback,
  useEffect,
  useRef,
  useState,
  useMemo,
} from 'react';

import Link from 'next/link';
import { RemoveScroll } from 'react-remove-scroll';
import { useRouter } from 'next/router';
import { IHamburgerMenuItem } from '@src/models/IHamburgerMenuItem';
import { ILeftMenu } from '@src/models/ILeftMenu';
import { getLeftMenuData } from '@src/utils/getLeftMenuData';
import MobileMenuItem from './MobileMenuItem';
import { ISingleLink } from '@src/models/ISingleLink.model';
import { Logo } from '../Logo';
import {
  Nav,
  List,
  ListItem,
  StyledLink,
  Overlay,
  OverlayContainer,
  Wrapper,
  MenuTrigger,
} from './MobileMenu.styled';
import get from 'lodash/get';

import { default as CloseIcon } from 'public/close-icon.svg';
import { default as HamburgerIcon } from 'public/hamburger.svg';
interface MobileMenuProps {
  enableLogo?: boolean;
  hamburgerMenus: IHamburgerMenuItem[];
  headerTopContactLink: ISingleLink;
  headerTopSearchLink: ISingleLink;
  headerTopLoginLink: ISingleLink;
  logoSrc?: string;
}

export const MobileMenu: FC<MobileMenuProps> = ({
  enableLogo,
  hamburgerMenus,
  headerTopContactLink,
  headerTopSearchLink,
  headerTopLoginLink,
  logoSrc
}) => {
  const router = useRouter();
  const menuRef = useRef();

  const menuData: ILeftMenu[] = useMemo(() => {
    return getLeftMenuData(hamburgerMenus);
  }, [hamburgerMenus]);

  const [showOverlay, setShowOverlay] = useState(false);
  const [isVisible, setIsVisible] = useState<boolean>(true);
  const [menuHeight, setMenuHeight] = useState(null);
  const [currentPath, setCurrentPath] = useState(null);
  const headerTopContactLinkUrl = get(headerTopContactLink, 'url');
  const headerTopSearchLinkUrl = get(headerTopSearchLink, 'url');
  const headerTopLoginLinkUrl = get(headerTopLoginLink, 'url');
  const getMenuHeight = useCallback(node => {
    if (node !== null) {
      setMenuHeight(node.clientHeight);
    }
  }, []);

  useEffect(() => {
    getMenuHeight(menuRef?.current);
  }, [getMenuHeight]);

  const listenToScroll = useCallback(() => {
    const winScroll =
      document.body.scrollTop || document.documentElement.scrollTop;

    if (winScroll > menuHeight) {
      isVisible && setIsVisible(false);
    } else {
      setIsVisible(true);
    }
  }, [isVisible, menuHeight]);

  useEffect(() => {
    setCurrentPath(router.asPath);
    if (router.asPath !== currentPath) {
      setShowOverlay(false);
    }
    window.addEventListener('scroll', listenToScroll);
    return () => window.removeEventListener('scroll', listenToScroll);
  }, [listenToScroll, setCurrentPath, router.asPath, currentPath]);

  return (
    <Wrapper
      isHomepage={router.asPath === '/'}
      ref={menuRef}
      isVisible={isVisible}
    >
      <RemoveScroll enabled={showOverlay}>
        <Nav withBg={showOverlay}>
          {enableLogo && <Logo logoSrc={logoSrc} />}
          <MenuTrigger onClick={() => setShowOverlay(!showOverlay)}>
            {!showOverlay ? <HamburgerIcon /> : <CloseIcon />}
          </MenuTrigger>
        </Nav>

        <Overlay show={showOverlay}>
          <OverlayContainer>
            <List border>
              <ListItem>
                <Link href="/" passHref>
                  <StyledLink active={router.asPath === '/'}>Home</StyledLink>
                </Link>
              </ListItem>
              {menuData.map(menu => (
                <MobileMenuItem
                  menu={menu}
                  key={menu.name}
                  closeMobileMenu={() => setShowOverlay(false)}
                />
              ))}
            </List>
            <List>
              {headerTopContactLinkUrl && (
                <ListItem>
                  <Link href={headerTopContactLinkUrl} passHref>
                    <StyledLink
                      active={router.pathname === headerTopContactLinkUrl}
                    >
                      {headerTopContactLink.name}
                    </StyledLink>
                  </Link>
                </ListItem>
              )}
              {headerTopSearchLinkUrl && (
                <ListItem>
                  <Link href={headerTopSearchLinkUrl} passHref>
                    <StyledLink
                      active={router.pathname === headerTopSearchLinkUrl}
                    >
                      {headerTopSearchLink.name || 'Search'}
                    </StyledLink>
                  </Link>
                </ListItem>
              )}
              {headerTopLoginLinkUrl && (
                <ListItem>
                  <Link href={headerTopLoginLinkUrl} passHref>
                    <StyledLink
                      active={router.pathname === headerTopLoginLinkUrl}
                    >
                      {headerTopLoginLink.name}
                    </StyledLink>
                  </Link>
                </ListItem>
              )}
            </List>
          </OverlayContainer>
        </Overlay>
      </RemoveScroll>
    </Wrapper>
  );
};
