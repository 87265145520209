import styled from '@emotion/styled';
import { sansSerifSemiBold } from '@src/theme/fonts';

export const Nav = styled.nav(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  paddingRight: theme.spacings.xl,
  position: 'fixed',
  top: 0,
  bottom: 0,
  left: 0,
  width: 330,
}));

export const List = styled.ul({
  fontFamily: 'Chronicle Light',
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  alignItems: 'flex-end',
  position: 'relative',
  top: '16%',
});

export const ListItem = styled.li<{ active: boolean }>(({ theme, active }) => ({
  font: theme.fonts.menu,
  fontSize: '22px',
  lineHeight: 1.8,
  fontWeight: '100',
  display: 'flex',
  color: theme.colors.black,
  flexDirection: 'column',
  position: 'relative',

  a: {
    textDecoration: 'none',
  },

  ':after': {
    content: '""',
    display: 'inline-block',
    width: 4,
    top: 4,
    height: '86%',
    position: 'absolute',
    right: -28,
    background: 'transparent',
    transition: 'all 200ms ease',
    opacity: 0,

    ...(active && {
      opacity: 1,
      background: theme.colors.main100,
    }),
  },
}));

export const SubMenu = styled.div(({ theme }) => ({
  display: 'flex',
  font: theme.fonts.menu,
  fontSize: '16px',
  lineHeight: 1.8,
  textAlign: 'right',
  justifyContent: 'flex-end',

  ul: {
    marginBottom: theme.spacings.m,
  },

  li: {
    listStyle: 'none',
  },
}));

export const StyledLink = styled.a<{ active: boolean }>(
  ({ theme, active }) => ({
    display: 'block',
    width: '100%',
    color: theme.colors.black,
    textAlign: 'right',

    after: {
      content: '""',
      width: 2,
      height: '100%',
      position: 'absolute',
      right: 0,
      background: theme.colors.main100,
    },

    ...(active && {
      color: theme.colors.main100,
    }),
  }),
);

export const StyledChildLink = styled(StyledLink)<{ active: boolean }>(
  ({ theme, active }) => ({
    opacity: 0.7,
    fontSize: 15,
    ...(active && {
      color: theme.colors.black,
      fontFamily: sansSerifSemiBold,
    }),
  }),
);
