import styled from '@emotion/styled';
import { Grid } from '../Grid';

export const Wrapper = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'baseline',
  fontFamily: theme.fonts.roboto,
  fontSize: 14,
  textTransform: 'uppercase',
  fontWeight: 700,
  color: theme.colors.main100,
  borderTop: `1px solid ${theme.colors.main100}`,
  paddingTop: theme.spacings.xl,
  paddingBottom: theme.spacings.xl,
  marginTop: 30,
  columnGap: 30,
  flexWrap: 'wrap',

  [theme.mediaQueries.medium]: {
    marginTop: 60,
    justifyContent: 'center',
    flexDirection: 'row',
  },

  [theme.mediaQueries.large]: {
    marginTop: 100,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
}));

export const List = styled.ul(({ theme }) => ({
  listStyle: 'none',
  alignItems: 'center',
  margin: 0,

  [theme.mediaQueries.medium]: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-end',
  },
}));

export const ListItem = styled.li(({ theme }) => ({
  margin: '0 16px 16px 0',

  a: {
    textDecoration: 'none',
    color: theme.colors.main100,
  },

  [theme.mediaQueries.large]: {
    margin: 0,
    marginBottom: theme.spacings.s,
    marginRight: theme.spacings.l,

    '&:last-of-type': {
      marginRight: 0,
    },
  },
}));

export const Copy = styled.div(({ theme }) => ({
  marginBottom: theme.spacings.m,
  display: 'flex',
  justifyContent: 'center',
}));

export const FooterGrid = styled(Grid)({
  marginTop: 'auto',
});
