import React, { FC } from 'react';
import { Wrapper } from './Text.styled';

type TextProps = {
  color?: 'light' | 'default';
  size?: 'small' | 'medium' | 'default';
};

export const RichText: FC<TextProps> = ({
  children,
  color = 'default',
  ...restProps
}) => {
  return (
    <Wrapper color={color} {...restProps}>
      {children}
    </Wrapper>
  );
};
