import { IHamburgerMenuItem } from '@src/models/IHamburgerMenuItem';
import { ILeftMenu } from '@src/models/ILeftMenu';

export function getLeftMenuData(
  hamburgerMenus: IHamburgerMenuItem[],
): ILeftMenu[] {
  const result = [];
  hamburgerMenus?.filter(item => item.fields.title !== 'Home').forEach(hamburgerMenu => {
    const menu: ILeftMenu = {
      urlSegment: '',
      name: '',
      childPages: [],
    };
    hamburgerMenu.fields.menu?.forEach((data, menuIndex) => {
      if (menuIndex) {
        menu.childPages.push({
          ...data,
        });
      } else {
        menu.name = data.contentName;
        menu.isActive = data.isActive;
        menu.urlSegment = data.contentSegmentUrl || '';
      }
    });
    result.push(menu);
  });
  return result;
}
