import React, { FC } from 'react';
import { Header, TagLine } from './MainHeader.styled';
import { UserActions } from '../UserActions';
import { Grid } from '../Grid';
import { ISingleLink } from '@src/models/ISingleLink.model';
import parseHtml from 'html-react-parser';
import { useDesktop } from '@src/hooks/useDevice';

type ContentHeaderProps = {
  tagLineContent: string | null;
  headerTopContactLink: ISingleLink;
  headerTopSearchLink: ISingleLink;
  headerTopLoginLink: ISingleLink;
};

export const MainHeader: FC<ContentHeaderProps> = ({
  tagLineContent,
  ...rest
}) => {
  const isLargeDevice = useDesktop();

  return (
    <Grid size="l">
      <Header>
        {isLargeDevice && (
          <>
            <TagLine hide={!tagLineContent}>
              {parseHtml(tagLineContent)}
            </TagLine>
            <UserActions {...rest} />
          </>
        )}
      </Header>
    </Grid>
  );
};
