import React, { FC, useCallback, useEffect, useState } from 'react';
import { USER_ACCEPT_COOKIES } from '@src/const/storage-keys';
import HTMLRender from '../HTMLRender';
import {
  CookieBanner,
  AcceptCookies,
  CookieBannerContent,
} from './AcceptCookiesBanner.styled';

interface AcceptCookiesBannerProps {
  cookieBannerText?: string;
}

export const AcceptCookiesBanner: FC<AcceptCookiesBannerProps> = ({
  cookieBannerText,
}) => {
  const [isHideBanner, setHideBanner] = useState(true);
  const handleAcceptAndClose = useCallback(() => {
    localStorage.setItem(USER_ACCEPT_COOKIES, 'Accepted');
    setHideBanner(true);
  }, []);
  useEffect(() => {
    const shouldHideBanner = localStorage.getItem(USER_ACCEPT_COOKIES);
    if (!shouldHideBanner) {
      setHideBanner(false);
    }
  }, []);
  if (isHideBanner) {
    return null;
  }
  return (
    <CookieBanner>
      <CookieBannerContent>
        <HTMLRender htmlContent={cookieBannerText} />
        <AcceptCookies onClick={handleAcceptAndClose}>
          Accept and close
        </AcceptCookies>
      </CookieBannerContent>
    </CookieBanner>
  );
};
