import Link from 'next/link';
import React, { FC } from 'react';
import { StyledButtonLink } from './ButtonLink.styled';

type Props = {
  href?: string;
  target?: string;
};

export const ButtonLink: FC<Props> = ({ href, children, target }) => {
  return (
    <Link href={href} passHref>
      <StyledButtonLink target={target}>{children}</StyledButtonLink>
    </Link>
  );
};
