import styled from '@emotion/styled';

export const Main = styled.main<{ isHomePage?: boolean }>(
  ({ theme, isHomePage }) => ({
    minHeight: '100vh',
    paddingBottom: 0,
    paddingTop: theme.spacings.xl,

    [theme.mediaQueries.medium]: {
      display: 'flex',
      flexDirection: 'column',
      overflowX: 'auto',
    },

    [theme.mediaQueries.large]: {
      padding: theme.spacings.xl,
      paddingLeft: 360,
    },

    //@todo find another solution to hide intro tekst on mobile
    ...(isHomePage && {
      'div[data-block-type]:first-of-type': {
        h1: {
          display: 'none',
          [theme.mediaQueries.large]: {
            display: 'flex',
          },
        },
      },
    }),
  }),
);

export const FixedImg = styled.img(({ theme }) => ({
  background: theme.colors.main100,
  width: '100vw',
  height: 460,
  objectFit: 'cover',
  zIndex: '-1',

  [theme.mediaQueries.large]: {
    background: 'none',
    display: 'block',
    left: 0,
    top: 0,
    position: 'fixed',
    height: '100vh',
  },
}));

export const Picture = styled.div<{ height?: string | number }>(
  ({ height = 'auto' }) => ({
    position: 'relative',
    width: '100%',
    height,

    img: {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
    },
  }),
);

export const GridRow = styled.div(({ theme }) => ({
  marginBottom: theme.spacings.xl,

  [theme.mediaQueries.large]: {
    marginBottom: theme.spacings.xxl,
  },
}));

export const StyledLink = styled.a(({ theme }) => ({
  textDecoration: 'underline',
  color: theme.colors.main100,
}));

export const DefaultList = styled.ul({
  listStyle: 'none',
});

// ============================================= Form Elements
export const SearchForm = styled.div(({ theme }) => ({
  position: 'relative',
  marginBottom: theme.spacings.xl,
}));

export const Input = styled.input(({ theme }) => ({
  background: theme.colors.lightGrey,
  padding: `16px ${theme.spacings.m}px`,
  font: theme.fonts.body,
  lineHeight: 1,
  fontSize: 22,
  width: '100%',
  border: `1px solid ${theme.colors.lightGrey}`,
  WebkitAppearance: 'none',

  ':focus': {
    outline: 'none',
    border: `1px solid ${theme.colors.grey}`,
  },

  '::placeholder,::-webkit-input-placeholder': {
    color: theme.colors.grey,
  },

  '::-webkit-search-cancel-button,::-webkit-search-decoration': {
    WebkitAppearance: 'none',
    appearance: 'none',
  },
}));
