import styled from '@emotion/styled';
import { default as Arrow } from 'public/arrow-right-small.svg';

// mobile navigation
export const Wrapper = styled.div<{
  isHomepage?: boolean;
  isVisible?: boolean;
}>(({ theme, isHomepage, isVisible }) => ({
  background: theme.colors.main100,
  position: 'sticky',
  top: 0,
  left: 0,
  right: 0,
  zIndex: 1010,
  transition: '300ms all ease',

  ...(isHomepage && {
    background: isVisible ? 'transparent' : theme.colors.main100,
    position: 'fixed',

    ':after': {
      content: '""',
      background: theme.colors.main100,
      width: '100vw',
      height: 'inherit',
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      zIndex: -1,
    },
  }),
}));

export const Overlay = styled.div<{ show?: boolean }>(({ theme, show }) => ({
  background: theme.colors.main100,
  width: '100vw',
  height: '100%',
  color: theme.colors.white,
  display: 'none',
  position: 'fixed',
  left: 0,
  bottom: 0,
  webkitOverflowScrolling: 'touch',
  overflowY: 'scroll',

  ...(show && {
    display: 'block',
  }),
}));

export const NavHeader = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  padding: theme.spacings.l,
}));

export const Nav = styled.nav<{ withBg?: boolean }>(({ theme, withBg }) => ({
  display: 'flex',
  padding: theme.spacings.l,
  position: 'relative',
  zIndex: 900,

  ...(withBg && { background: theme.colors.main100 }),
}));

export const OverlayContainer = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacings.l,
  paddingTop: 135,
}));

export const List = styled.ul<{ border?: boolean }>(({ theme, border }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: 'auto',
  margin: 0,
  marginBottom: theme.spacings.l,

  li: {
    listStyle: 'none',
  },

  ...(border && {
    '>li': {
      paddingTop: 6,
      paddingBottom: 6,
      borderBottom: `1px solid ${theme.colors.white}`,
    },
  }),
}));

export const SubMenu = styled.ul<{ active?: boolean }>(({ theme, active }) => ({
  font: theme.fonts.menu,
  fontSize: 16,
  lineHeight: 1.4,
  margin: 0,
  padding: 0,
  display: 'none',
  flexDirection: 'column',
  marginBottom: theme.spacings.s,

  ...(active && {
    display: 'flex',
  }),

  li: {
    lineHeight: 1,
  },
}));

export const ListItem = styled.li(({ theme }) => ({
  display: 'flex',
  font: theme.fonts.menu,
  fontSize: '22px',
  lineHeight: 1.4,
  fontWeight: 100,
  position: 'relative',
  alignItems: 'center',
  justifyContent: 'space-between',
}));

export const StyledLink = styled.a<{ active: boolean }>(
  ({ theme, active }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    color: theme.colors.white,
    textDecoration: 'none',
    padding: `${theme.spacings.s}px 0`,
    alignItems: 'center',

    ...(active && {
      color: theme.colors.white,
    }),
  }),
);

export const StyledChildLink = styled(StyledLink)<{ active: boolean }>(
  ({ theme, active }) => ({
    ...(active && {
      color: theme.colors.white,
      fontWeight: 700,
    }),
  }),
);

export const StyledArrow = styled(Arrow)<{ active: string }>(
  ({ theme, active }) => ({
    transform: 'rotate(90deg)',
    marginRight: theme.spacings.m,
    position: 'absolute',
    right: 0,
    top: 24,

    ...(active === 'true' && {
      color: theme.colors.white,
      opacity: 0.5,
      fontWeight: 700,
    }),
  }),
);

export const MenuTrigger = styled.div(({ theme }) => ({
  position: 'absolute',
  top: theme.spacings.l,
  right: theme.spacings.l,
  width: 50,
  height: 50,
  border: `1px solid ${theme.colors.white}`,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));
