import React, { FC } from 'react';
import { Wrapper } from './Grid.styled';

type GridProps = {
  size?: string; // s, m, l
  className?: string;
};

export const Grid: FC<GridProps> = ({ children, ...restProps }) => {
  return <Wrapper {...restProps}>{children}</Wrapper>;
};
