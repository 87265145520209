import { useEffect } from 'react';
import { NEXT_PUBLIC_STAGING_HOST } from '@src/const/config';
import { useRouter } from 'next/router';
interface Props {
  timeBuild?: number;
}

const StagingRefresh = ({ timeBuild }: Props) => {
  const router = useRouter();
  useEffect(() => {
    if (location) {
      const isStagingHost = location.host === NEXT_PUBLIC_STAGING_HOST;
      const isNextAndUpdatePage = location.pathname.startsWith('/news-updates');
      const timeRevalidateInvalid =
        Number(
          process.env.NEXT_PUBLIC_REVALIDATE_TIMEOUT_PAGE_NEWS_UPDATE || 1,
        ) *
          60 *
          1000 +
        Number(timeBuild || 0);
      const isNextAndUpdatePageNeedRebuild =
        isNextAndUpdatePage && new Date().getTime() > timeRevalidateInvalid;
      const shouldRebuildPage = isStagingHost || isNextAndUpdatePageNeedRebuild;
      if (shouldRebuildPage) {
        fetch(
          `/api/revalidate?secret=${process.env.NEXT_PUBLIC_REVALIDATE_TOKEN}&url=${location.pathname}`,
        );
      }
    }
  }, [router]);
  return null;
};

export default StagingRefresh;
