import styled from '@emotion/styled';

export const CookieBanner = styled.div(({ theme }) => ({
  position: 'fixed',
  bottom: 0,
  left: 0,
  right: 0,
  background: theme.colors.main100,
  padding: theme.spacings.l,
  color: theme.colors.white,
  zIndex: 9999,

  [theme.mediaQueries.large]: {
    width: '60vw',
    margin: theme.spacings.l,
  },
}));

export const CookieBannerContent = styled.div(({ theme }) => ({
  [theme.mediaQueries.large]: {
    width: '50vw',
  },
}));

export const AcceptCookies = styled.span({
  cursor: 'pointer',

  ':hover': {
    textDecoration: 'underline',
    textUnderlineOffset: 4,
  },
});
