import styled from '@emotion/styled';
import { motion } from 'framer-motion';

export const Wrapper = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacings.l,
  paddingBottom: 0,
  width: '100%',
  minHeight: '100vh',

  [theme.mediaQueries.medium]: {
    padding: theme.spacings.xxl,
    paddingBottom: 0,
  },
}));

export const Header = styled.div(({ theme }) => ({
  display: 'none',
  paddingTop: 0,
  justifyContent: 'space-between',
  marginBottom: 70,
  height: 100,

  [theme.mediaQueries.large]: {
    display: 'flex',
    paddingTop: theme.spacings.xxl,
  },
}));

export const TagLine = styled.h2<{ hide?: boolean }>(({ theme, hide }) => ({
  fontSize: 24,
  lineHeight: 1.5,
  fontWeight: 'normal',
  display: 'flex',
  color: theme.colors.main100,
  opacity: 1,
  transition: 'all 100ms ease',
  paddingTop: 1,

  ...(hide && {
    opacity: 0,
  }),
}));

export const Body = styled(motion.div)({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
});
