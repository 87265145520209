import React, { useEffect } from 'react';
import { IBlock } from '@src/models/IBlock';
import dynamic from 'next/dynamic';

const FAQGroup = dynamic(() => import('src/blocks/FAQGroup/eTFAQs'));
const FAQHeaderTitle = dynamic(
  () => import('src/blocks/FAQHeaderTitle/eTText'),
);
const Heros = dynamic(() => import('@src/blocks/Heros/eTHeros'));
const Update = dynamic(() => import('@src/blocks/Update/eTUpdate'));
const ImageCards = dynamic(() => import('@src/blocks/ImageCards/eTImageCards'));
const SingleTestimonial = dynamic(
  () => import('@src/blocks/SingleTestimonial/eTSingleTestimonial'),
);
const Step = dynamic(() => import('@src/blocks/Step/eTStep'));
const Team = dynamic(() => import('@src/blocks/Team/eTTeam'));
const HtmlContent = dynamic(() => import('@src/blocks/HtmlContent/eTContent'));
const Image = dynamic(() => import('@src/blocks/Image/eTImage'));
const ImageCardsV2 = dynamic(
  () => import('@src/blocks/ImageCardsV2/eTImageCardsV2'),
);
const Promotion = dynamic(() => import('@src/blocks/Promotion/eTPromotion'));
const ListItemContent = dynamic(
  () => import('@src/blocks/ListItemContent/eTListItemContent'),
);
const ImageMedium = dynamic(
  () => import('@src/blocks/ImageMedium/eTImageMedium'),
);
const PromotionBlock3070 = dynamic(
  () => import('@src/blocks/PromotionBlock3070/eTPromotion3070'),
);

const PeopleItems = dynamic(
  () => import('@src/blocks/PeopleItems/eTPeopleItems'),
);
const FellowsTable = dynamic(
  () => import('@src/blocks/FellowsTable/eTFellows'),
);

const FellowDetail = dynamic(
  () => import('@src/blocks/FellowDetail/eTFellowItem'),
);

const FellowsCarousel = dynamic(
  () => import('@src/blocks/FellowsCarousel/eTFellowsCarousel'),
);

const GroupTab = dynamic(() => import('@src/blocks/GroupTab/groupTab'));
const LandscapeImageHeader = dynamic(
  () => import('@src/blocks/LandscapeImageHeader/eTImageGreyBg'),
);

const ContentGrey = dynamic(
  () => import('@src/blocks/ContentGrey/eTContentGreyBg'),
);

const ImageCardSlider = dynamic(
  () => import('@src/blocks/ImageCardSlider/eTImageCardsGreyBg'),
);

const Search = dynamic(
  () => import('@src/blocks/Search/eTSearchConfigurations'),
);

const NextPrevCard = dynamic(
  () => import('@src/blocks/NextPrevCard/getNextPrevNews'),
);

const LatestUpdate = dynamic(
  () => import('@src/blocks/LatestUpdate/eTLatestNews'),
);

interface BlockRenderProps {
  block: IBlock;
}

function UndefinedBlock(props: BlockRenderProps) {
  const { block } = props;
  useEffect(() => {
    console.error(
      `Block is not defined, block type: ${block.system.contentType}`,
    );
  }, []);
  return (
    <div
      style={{ opacity: 1 }}
    >{`Block is not defined, block type: ${block.system.contentType}`}</div>
  );
}

const blockRender = {
  eTLatestNews: LatestUpdate,
  getNextPrevNews: NextPrevCard,
  eTSearchConfigurations: Search,
  eTImageCardsGreyBg: ImageCardSlider,
  eTContentGreyBg: ContentGrey,
  eTImageGreyBg: LandscapeImageHeader,
  groupTab: GroupTab,
  eTHeros: Heros,
  eTFellowsCarousel: FellowsCarousel,
  eTImageCardsV2: ImageCardsV2,
  eTText: FAQHeaderTitle,
  // eTFellows: FollowsSlider,
  eTFAQs: FAQGroup,
  eTUpdate: Update,
  eTImageCards: ImageCards,
  eTSingleTestimonial: SingleTestimonial,
  eTStep: Step,
  eTTeam: Team,
  eTContent: HtmlContent,
  eTImage: Image,
  eTPromotion: Promotion,
  eTListItemContent: ListItemContent,
  eTImageMedium: ImageMedium,
  eTPromotion3070: PromotionBlock3070,
  eTPeopleItems: PeopleItems,
  eTFellows: FellowsTable,
  eTFellowItem: FellowDetail,
};

const BlockRender = (props: BlockRenderProps) => {
  const block = props.block;
  const RenderComponent =
    blockRender[block.system.contentType] || UndefinedBlock;
  return <RenderComponent block={block} />;
};

export default BlockRender;
