import { window } from 'global';
import { useState, useEffect } from 'react';

/**
 *
 * @param mediaQuery
 * @returns
 *    true - if media query matched
 *    false - if media query NOT matched
 *    undefined - if window.matchMedia not available (e.g. for server-side rendering)
 */
const useMediaQuery = (mediaQuery: string): boolean | null | undefined => {
  const [queryMatch, setQueryMatch] = useState(
    window !== undefined && window?.matchMedia?.(mediaQuery).matches,
  );

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (window?.matchMedia) {
      const mqMatchHandler = e => setQueryMatch(e.matches);
      const mediaQueryList = window.matchMedia(mediaQuery);
      mediaQueryList.addListener(mqMatchHandler);

      return () => mediaQueryList.removeListener(mqMatchHandler);
    }
  }, [mediaQuery]);

  return queryMatch;
};

export default useMediaQuery;