import styled from '@emotion/styled';
import Link from 'next/link';

export const LogoContainer = styled.div(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  margin: 0,
  flexShrink: 0,

  [theme.mediaQueries.large]: {
    background: theme.colors.main100,
    width: 330,
    height: 228,
  },

  svg: {
    width: 240,
  },

  img: {
    width: 240,
  },

  [theme.mediaQueries.medium]: {
    display: 'flex',
    alignItems: 'center',
  },

  [theme.mediaQueries.large]: {
    background: theme.colors.main100,
    width: 330,
    height: 228,

    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

export const LogoLink = styled(Link)({
  display: 'block',
});
