import { useDesktop } from '@src/hooks/useDevice';
import { FixedImg } from '@src/theme/Global.styled';
import React, { FC } from 'react';
import { Loader } from '../Loader';
import { Text } from '../Text';
import { Wrapper, Picture, Copy, HeroTitle } from './Hero.styled';

type HeroProps = {
  imgSrc: string;
  imgDescription?: string;
  copy?: string;
  title?: string | React.ReactElement | React.ReactElement[];
  type?: string;
};

export const Hero: FC<HeroProps> = ({
  imgSrc,
  imgDescription,
  copy,
  title,
  type,
}) => {
  const isLargeDevice = useDesktop();

  if (!imgSrc) return null;

  return (
    <Wrapper type={type}>
      <Picture>
        <Loader>
          {title && !isLargeDevice && <HeroTitle>{title}</HeroTitle>}
          {type === 'fixed' ? (
            <FixedImg src={imgSrc} alt={imgDescription || ''} />
          ) : (
            <img src={imgSrc} alt={imgDescription || ''} />
          )}
        </Loader>
        {copy && (
          <Copy>
            <Text size="small" color="light">
              {copy}
            </Text>
          </Copy>
        )}
      </Picture>
    </Wrapper>
  );
};
