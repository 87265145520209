import React, { FC } from 'react';
import { Wrapper } from './Text.styled';

type TextProps = {
  size?: 'small' | 'medium' | 'default';
  color?: 'light' | 'default';
  style?: any;
};

export const Text: FC<TextProps> = ({
  children,
  size = 'default',
  color = 'default',
  ...restProps
}) => {
  return (
    <Wrapper size={size} color={color} {...restProps}>
      {children}
    </Wrapper>
  );
};
