import Link from 'next/link';
import React, { FC } from 'react';
import get from 'lodash/get';
import { ButtonLink } from '../ButtonLink';
import { default as Search } from 'public/magnify-glass.svg';
import { List, ListItem, StyledLink } from './UserActions.styled';
import { ISingleLink } from '@src/models/ISingleLink.model';

interface UserActionsProps {
  headerTopContactLink: ISingleLink;
  headerTopSearchLink: ISingleLink;
  headerTopLoginLink: ISingleLink;
}

export const UserActions: FC<UserActionsProps> = ({
  headerTopContactLink,
  headerTopSearchLink,
  headerTopLoginLink,
}) => {
  return (
    <nav>
      <List>
        <ListItem>
          <Link href={get(headerTopContactLink, 'url', '#')} passHref>
            <StyledLink
              target={get(headerTopContactLink, 'target', '_self') || '_self'}
              aria-label="contact"
            >
              {get(headerTopContactLink, 'name', '')}
            </StyledLink>
          </Link>
        </ListItem>
        <ListItem>
          <Link href={get(headerTopSearchLink, 'url', '')} passHref>
            <StyledLink
              target={get(headerTopSearchLink, 'target', '_self') || '_self'}
              aria-label="search"
            >
              <Search />
            </StyledLink>
          </Link>
        </ListItem>
        <ListItem>
          <ButtonLink
            href={get(headerTopLoginLink, 'url', '')}
            target={get(headerTopLoginLink, 'target', '_self') || '_self'}
          >
            {get(headerTopLoginLink, 'name', '')}
          </ButtonLink>
        </ListItem>
      </List>
    </nav>
  );
};
