import styled from '@emotion/styled';

export const Content = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  flex: 1,

  [theme.mediaQueries.large]: {
    paddingTop: theme.spacings.xxl,
  },
}));
