import styled from '@emotion/styled';

export const Wrapper = styled.div({
  position: 'fixed',
  left: 0,
  width: 330,
  height: '100vh',
  zIndex: 999,
});

export const List = styled.ul(({ theme }) => ({
  display: 'flex',
  marginTop: 10,
  margin: 0,
  fontFamily: theme.fonts.roboto,
  fontSize: '14px',
  alignItems: 'center',
}));

export const ListItem = styled.li(({ theme }) => ({
  fontWeight: 'bold',
  textTransform: 'uppercase',
  display: 'flex',
  color: theme.colors.main100,
  lineHeight: 1,
  marginLeft: theme.spacings.xl,

  a: {
    textDecoration: 'none',
  },
}));

export const StyledLink = styled.a(({ theme }) => ({
  color: theme.colors.main100,
}));
